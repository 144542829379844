import { Tooltip } from "antd"

export default function BotLicenseTooltip(props) {
    const {licenseName, children, withIcon = true, ...rest} = props
    return (
        <Tooltip
            title={`You do not have ${licenseName} Bot licence`}
            color="#1890ff"
            zIndex={9999}
            {...rest}
        >
            {children} {withIcon && <i className="flexno px-2 chelp fal fa-info-circle" />}
        </Tooltip>
    )
}