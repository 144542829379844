const rpaEndpoints = {
    saveRpa(data) {
        return {
            url: '/api/rpa/save',
            method: 'POST',
            data,
        }
    },
    updateRpa(data) {
        return {
            url: '/api/rpa/update',
            method: 'POST',
            data,
        }
    },
    executedRpa(data) {
        return {
            url: '/api/rpa/executed',
            method: 'POST',
            data,
        }
    },
    testRpa(data) {
        return {
            url: '/api/rpa/execute/test',
            method: 'POST',
            data,
        }
    },
    getRpaDetail(id) {
        return {
            url: `/api/rpa/detail/${id}`,
            method: 'GET'
        }
    },
    deleteRpa(id) {
        return {
            url: `/api/rpa/delete/${id}`,
            method: 'DELETE',
        }
    },
    fetchRpaDatatables({ data }) {
        return {
            url: '/api/rpa/datatables',
            method: 'POST',
            data,
        }
    },
    getRpaTrafficBotByDate(params) {
        return {
            url: '/api/rpa/dashboard/bot/daterange',
            method: 'POST',
            params
        }
    },
}

export default rpaEndpoints;