const connectHubEndpoints = {
    getConnectHubLinkedLookup({ data, params }) {
        return {
            url: `/api/bot/connecthublinkedlookup`,
            method: 'POST',
            params,
            data
        }
    },
    getSupportedPlatform() {
        return {
            url: `/api/manage/supportedplatforms`,
            method: 'GET',
        }
    },
    getStoreKeyDetail(id) {
        return {
            url: `/api/storekey/getbyid`,
            method: 'GET',
            params: {
                id,
            },
        }
    },
    saveStoreKey(data) {
        return {
            url: '/api/storekey/create',
            method: 'POST',
            data
        }
    },
    updateStoreKey(data) {
        return {
            url: '/api/storekey/update',
            method: 'PUT',
            data
        }
    },
    getStoreKeyDataTables(data) {
        return {
            url: '/api/storekey/datatables',
            method: 'POST',
            data: data.data
        }
    },
    deleteStoreKey(id) {
        return {
            url: '/api/storekey/delete',
            method: 'DELETE',
            params: {
                id,
            },
        }
    },
    getEcommerceDataTables() {
        return {
            url: '/api/ecommerce/getVariables',
            method: 'POST',
        }
    },
    saveEcommerce(data) {
        return {
            url: '/api/ecommerce/saveVariables',
            method: 'POST',
            data
        }
    },
    getEcommerceTrafficBotByDate(params) {
        return {
            url: '/api/ecommerce/hubtraffic/bot/daterangedashboard',
            method: 'POST',
            params
        }
    },
    getCompanyHouseTrafficBotByDate(params) {
        return {
            url: '/api/companyhouse/hubtraffic/bot/daterangedashboard',
            method: 'POST',
            params
        }
    },
    getTravelManagementTrafficBotByDate(params) {
        return {
            url: '/api/travelmanagement/hubtraffic/bot/daterangedashboard',
            method: 'POST',
            params
        }
    },
    getCompaniesHouseDataTables() {
        return {
            url: '/api/companyhouse/getVariables',
            method: 'POST',
        }
    },
    saveCompanies(data) {
        return {
            url: '/api/companyhouse/saveVariables',
            method: 'POST',
            data
        }
    },
    getTravelDataTables() {
        return {
            url: '/api/travelmanagement/getVariables',
            method: 'POST',
        }
    },
    saveTravel(data) {
        return {
            url: '/api/travelmanagement/saveVariables',
            method: 'POST',
            data
        }
    },
}

export default connectHubEndpoints