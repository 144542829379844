import { useQuery, useMutation } from "@tanstack/react-query"
import useApiRequest from "@utils/hooks/useApiRequest"

export const useGetPolicyConfig = (token, isResetPasswordPage) => {
  const { request } = useApiRequest()
  return useQuery({
    queryKey: [`get-policy-config`, token],
    queryFn: () => request("getPolicyConfig", isResetPasswordPage ? token : ''),
    enabled: token !== undefined
  })
}

export const useGetPolicySecurityQuestions = (token, isResetPasswordPage) => {
  const { request } = useApiRequest()
  return useQuery({
    queryKey: [`get-policy-security-questions`, token],
    queryFn: () => request("getPolicySecurityQuestions", isResetPasswordPage ? token : ''),
  })
}

export const useGetSharedKeyQRCode = (isAuthorized) => {
  const { request } = useApiRequest()
  return useQuery({
    queryKey: [`get-shared-key-qrcode`],
    queryFn: () => request("getSharedKeyQRCode"),
    enabled: isAuthorized
  })
}

export const useSavePolicySecurityQuestions = (token) => {
  const { request } = useApiRequest()
  return useMutation({
    mutationFn: (payload) => request("savePolicySecurityQuestions", { payload, token }),
  })
}

export const useEnableAuthentication = () => {
  const { request } = useApiRequest()
  return useMutation({
    mutationFn: (params) => request("enableAuthenticator", params),
  })
}
