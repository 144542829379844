/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useMemo, useEffect, useState } from "react";
// import { useLocation } from "react-router";
import { NavLink, useLocation } from "react-router-dom";
import { shallowEqual, useSelector } from "react-redux";
import SVG from "react-inlinesvg";
import useDatasetApi from "@utils/hooks/useDatasetApi";

import { toAbsoluteUrl, checkIsActive } from "../../../../_helpers";

export function HeaderMenu({ layoutProps }) {
  const location = useLocation();
  const { getDataset } = useDatasetApi();
  const [userExtRefId, setUserExtRefId] = useState("");
  const getMenuItemActive = (url) => {
    return checkIsActive(location, url) ? " menu-item-active" : "";
  };

  const { menus, isSysAdmin, isAdministrator, isConsultant } = useSelector(
    ({ auth }) => ({
      menus: auth?.config?.appMenu?.lists.filter((val) => val.href !== "/settings"),
      ...auth?.config
    }),
    shallowEqual
  );
  const fetchUserExtRefId = async () => {
    const { data } = await getDataset({ id: "application_user_extension" });
    setUserExtRefId(data?.id);
  }

  useEffect(() => {
    if (isSysAdmin || isAdministrator || isConsultant) {
      fetchUserExtRefId();
    }
  }, [isSysAdmin, isAdministrator])

  const headerMenus = useMemo(() => {
    let hMenus = menus;
    if (userExtRefId) {
      hMenus.push(
        {
          id: "_user_extension",
          title: "User Extension",
          href: `/referencedata/${userExtRefId}`
        },
      )
    }
    if (isSysAdmin || isAdministrator) {
      hMenus.push(
        {
          id: "_rpa",
          title: "RPA",
          href: `/robotic-automation`
        },
        {
          id: "_webhooks",
          title: "Webhooks",
          href: `/webhooks`,
        },
        {
          id: "_connect_hub",
          title: "Connect Hub",
          href: `/connect-hub`,
          items: [{
            id: "_companies_house",
            title: "Companies House",
            href: "/connect-hub/companies-house",
            items: []
          },
          {
            id: "_ecommerce",
            title: "eCommerce",
            href: "/connect-hub/ecommerce",
            items: []
          },
          {
            id: "_travel_management",
            title: "Travel Management",
            href: "/connect-hub/travel-management",
            disabled: true,
            items: []
          }]
        }
      )
    }
    if (!isSysAdmin && !isAdministrator && !isConsultant) {
      hMenus = hMenus.filter(m => m.href !== "/module-categories");
    }
    return hMenus;
  }, [menus, userExtRefId, isSysAdmin, isAdministrator, isConsultant])

  const GenerateRootMenu = (item) => {
    if (item.items && item.items.length > 0) {
      return (
        <li
          key={"menu_item_rel" + item.id}
          data-menu-toggle="hover"
          aria-haspopup="true"
          className={"menu-item menu-item-submenu menu-item-rel" + getMenuItemActive(item.href)}
        >
          <NavLink to={item?.href} className="menu-link menu-toggle">
            <span className="menu-text">{item.title}</span>
            <i className="menu-arrow" />
          </NavLink>
          <div className="menu-submenu menu-submenu-classic menu-submenu-left">
            <ul className="menu-subnav">
              {item.items?.map((i) =>
                i.items?.length === 0 ? (
                  <li
                    key={"sub_nav_" + i.id}
                    className={"menu-item" + getMenuItemActive(i.href)}
                  >
                    <NavLink to={i.href} className="menu-link">
                      <span className="menu-text">{i.title}</span>
                    </NavLink>
                  </li>
                ) : (
                  <li
                    key={"sub_nav_" + i.id}
                    className={"menu-item menu-item-submenu" + getMenuItemActive("/google-material/inputs")}
                    data-menu-toggle="hover"
                    aria-haspopup="true"
                  >
                    {i.items?.map((r) => GenerateMenu(r))}
                  </li>
                )
              )}
            </ul>
          </div>
        </li>
      );
    } else {
      return (
        <li
          key={"menu_item_rel" + item.id}
          className={"menu-item menu-item-rel" + getMenuItemActive(item.href)}
        >
          <NavLink to={item.href} onClick={layoutProps.action} className="menu-link">
            <span className="menu-text">{item.title}</span>
            {layoutProps.rootArrowEnabled && <i className="menu-arrow" />}
          </NavLink>
        </li>
      );
    }
  };

  const GenerateMenu = (item) => {
    return (
      <React.Fragment key={"fragment_" + item.id}>
        <NavLink to={item.href} className="menu-link menu-toggle">
          {item.icon && (
            <span className="svg-icon menu-icon">
              <SVG title=" " src={toAbsoluteUrl(item.icon)} />
            </span>
          )}
          <span className="menu-text">{item.title}</span>
          <i className="menu-arrow" />
        </NavLink>
        <div className="menu-submenu menu-submenu-classic menu-submenu-right">
          <ul className="menu-subnav">
            {item.items && item.items.length > 0 ? (
              GenerateMenu(item)
            ) : (
              <li
                key={"sub_" + item.id}
                className={"menu-item" + getMenuItemActive(item.href)}
              >
                <NavLink to={item.href} className="menu-link">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">{item.title}</span>
                </NavLink>
              </li>
            )}
          </ul>
        </div>
      </React.Fragment>
    );
  };

  return (
    <div
      id="kt_header_menu"
      className={"header-menu header-menu-mobile " + layoutProps.ktMenuClasses}
      {...layoutProps.headerMenuAttributes}
    >
      <ul className={"menu-nav " + layoutProps.ulClasses}>
        {(headerMenus || []).map((m) => GenerateRootMenu(m))}
      </ul>
    </div>
  );
}
