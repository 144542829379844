import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { put, takeLatest, select } from "redux-saga/effects";
import { isAuthenticated } from './authCrud';
import * as menuActions from "../../ApplicationMenu/_redux/applicationMenuActions";
import LogRocket from 'logrocket';
import setupLogRocketReact from 'logrocket-react';

export const actionTypes = {
  Login: "[Login] Action",
  Logout: "[Logout] Action",
  LoginWith2Fa: "[LoginWith2Fa] Action",
  setAuthLoading: "[LOADING] ACTION",
  getInfo: "[getInfo] Action",
  TokenRequested: "[Request Token] Action",
  TokenLoaded: "[Token User] Auth API",
  RefreshToken: "[RefreshToken] Action",
  Redirect: "[Redirect] Action",
};

const initialAuthState = {
  requiresTwoFactor: false,
  authLoading: false,
  config: {},
  user: undefined,
  authToken: undefined,
  redirectUrl: undefined,
};

export const reducer = persistReducer(
  { storage, key: "v706-auth", whitelist: ["config", "authToken"] },
  (state = initialAuthState, action) => {
    switch (action.type) {
      case actionTypes.Login: {
        const { requiresTwoFactor } = action.payload;

        return { authLoading: true, requiresTwoFactor };
      }

      case actionTypes.TokenLoaded: {
        const { token, config } = action.payload;

        return { ...initialAuthState, authToken: token, authLoading: false, config };
      }

      case actionTypes.setAuthLoading: {
        const { loading } = action.payload;

        return { ...initialAuthState, authLoading: loading };
      }

      case actionTypes.getInfo: {
        const { user } = action.payload;

        return { ...initialAuthState, user, authLoading: false };
      }

      case actionTypes.Logout: {
        // TODO: Change this code. Actions in reducer aren't allowed.
        return initialAuthState;
      }

      case actionTypes.RefreshToken: {
        const { token } = action.payload;
        
        return { ...state, ...token };
      }

      case actionTypes.Redirect: {
        const { redirectUrl } = action.payload;
        return { ...state, redirectUrl: redirectUrl };
      }

      default:
        return state;
    }
  }
);

export const actions = {
  login: requiresTwoFactor => ({ type: actionTypes.Login, payload: { requiresTwoFactor } }),
  loginMfa: () => ({ type: actionTypes.LoginWith2Fa }),
  logout: () => ({ type: actionTypes.Logout }),
  setLoading: (loading) => ({ type: actionTypes.setAuthLoading, payload: { loading } }),
  requestToken: token => ({ type: actionTypes.TokenRequested, payload: { token } }),
  fulfillInfo: user => ({ type: actionTypes.getInfo, payload: { user } }),
  fulfillToken: (token, config) => ({ type: actionTypes.TokenLoaded, payload: { token, config } }),
  refreshToken: token => ({ type: actionTypes.RefreshToken, payload: { token } }),
  redirect: redirectUrl => ({ type: actionTypes.Redirect, payload: { redirectUrl } }),
};

const getRequiresTwoFactor = ({ auth }) => auth.requiresTwoFactor;

export function* saga() {

  yield takeLatest(actionTypes.Login, function* loginWithoutMfaSaga() {
    const auth = yield select(getRequiresTwoFactor);
    if (!auth) {
      yield put(actions.requestToken());
    }
  });

  yield takeLatest(actionTypes.Logout, function* logout() {
    yield put(menuActions.unControlMenu());
  });

  yield takeLatest(actionTypes.LoginWith2Fa, function* loginSaga() {
    yield put(actions.requestToken());
  });


  yield takeLatest(actionTypes.TokenRequested, function* userRequested() {
    const { data, status } = yield isAuthenticated();
    if(status === 403){
      yield put(actions.redirect("/403"));
    } else {
      const { success, data: token } = data;
      if (success) {
          const config = { 
            appMenu: token.config.appMenu, 
            isSysAdmin: token.isSysAdmin, 
            isAdministrator: token.isAdministrator, 
            isFullUser: token.isFullUser, 
            isConsultant: token.isConsultant,
            isMachineRemembered: token.isMachineRemembered, 
            is2faEnabled: token.is2faEnabled, 
            accessToken: token.accessToken, 
            userInfo: { 
              uid: token.uid, 
              username: token.username, 
              email: token.email, 
              fullname: token.fullname,
              config: token.config
            } 
          }
          if (
              process.env.NODE_ENV !== 'development' &&
              !window.location.host.match(/demo|localhost/) &&
              !!token
          ) {
            try {
              LogRocket.init('tb7dmt/fsa');
              LogRocket.identify(token.uid, {
                  email: token.email, 
                  name: token.fullname,
              });
              setupLogRocketReact(LogRocket);
            } catch (e) { }
          }
          yield put(actions.fulfillToken(token.token, config));
      }
    }
  });
}
