const rpaEndpoints = {
    getPolicyConfig(token) {
        const accessToken = token ? {
            headers: {
                'access_token': token
            },
        } : {}
        return {
            url: `/api/identity/manageauthenticator/policy`,
            method: 'GET',
            ...accessToken
        }
    },
    getPolicySecurityQuestions(token) {
        const accessToken = token ? {
            headers: {
                'access_token': token
            },
        } : {}
        return {
            url: `/api/identity/manageauthenticator/policy/securityquestions`,
            method: 'GET',
            ...accessToken
        }
    },
    getSharedKeyQRCode() {
        return {
            url: `/api/identity/ManageAuthenticator/sharedKeyQRCodeUri`,
            method: 'GET',
        }
    },
    enableAuthenticator(params) {
        return {
            url: '/api/identity/ManageAuthenticator/enableAuthenticator',
            method: 'POST',
            params
        }
    },
    savePolicySecurityQuestions({ payload, token }) {
        const accessToken = token ? {
            headers: {
                'access_token': token
            },
        } : {}
        return {
            url: '/api/identity/manageauthenticator/policy/securityquestions/save',
            method: 'POST',
            data: payload,
            ...accessToken
        }
    },
}

export default rpaEndpoints;