import React from 'react';

import Flex from '../components/Flex';
import Head from '../components/Head';
import { toAbsoluteUrl } from '../../_metronic/_helpers';
import { APP_NAME, APP_YEAR } from '../../data/appData';
import { EmailOutlined } from '@material-ui/icons';
import { styled } from "@material-ui/core";
import { PhoneOutlined } from '@ant-design/icons';

const HelpIcon = styled('span')(() => ({
	display: 'inline-block',
	width: '16px',
	color: '#B5B5C3',
	marginRight: '.5rem',
	'& svg': {
		width: '16px',
	}
}))

export default function AuthLayout({
    title = "",
    children,
}) {
    const isLoginHost = window.location.host.split('.')[0] === 'login'
    const logo = isLoginHost ? toAbsoluteUrl("/media/logos/UniFi-White.svg") : toAbsoluteUrl("/media/logos/FinanSys-Apps-White.svg")
    return (
        <Flex dir="column" className="flex-root bg-white">
            {title && <Head title={title}/>}

            <Flex 
                id="kt_login"
                dir="column"
                className="flex-lg-row flex-row-fluid bg-white"
                align="center"
            >
                <Flex
                    className="col-12 col-md-6 flex-row-auto bgi-size-cover bgi-no-repeat p-24 justify-content-md-end  justify-content-center"
                >
                    <img
                        height={200}
                        loading="lazy"
                        alt={APP_NAME}
                        src={toAbsoluteUrl("/media/logos/UniFi_Logo.svg")}
                    />
                </Flex>

                <Flex dir="column" className="flex-row-fluid position-relative p-7 overflow-hidden">
                    <Flex 
                        className="flex-column-fluid flex-center mt-lg-0 p-2"
                        style={{
                            backgroundColor: "#0062a5",
                            maxWidth: '400px',
                            borderRadius: '8px',
                        }}
                    >
                        {children}
                    </Flex>
                </Flex>
            </Flex>
            <div className="d-flex flex-column flex-md-row justify-between align-center m-auto bg-white" style={{height: '100px', gap: '10px'}}>
                <div className="m-auto">
                    <HelpIcon>
                        <EmailOutlined className="font-weight-bold text-dark" />
                    </HelpIcon>
                    <a href="mailto:support@unifiplatform.com" className="email font-weight-bold text-dark">support@unifiplatform.com</a>
                </div>
                <div className="m-auto d-none d-md-block font-weight-bold text-dark">|</div>
                <div className="m-auto">
                    <HelpIcon>
                        <PhoneOutlined className="font-weight-bold text-dark" style={{transform: "scaleX(-1)"}} />
                    </HelpIcon>
                    <a href="tel:+442074569839" className="tel font-weight-bold text-dark">+44 20 7456 9839</a>
                </div>
                <div className="m-auto d-none d-md-block font-weight-bold text-dark">|</div>
                <div className="font-weight-bold text-dark m-auto">
                    &copy; {APP_YEAR} FinanSys Limited
                </div>
            </div>
        </Flex>
    );
}

