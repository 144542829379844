import React from 'react'
import Flex from '../../components/Flex'

export default function Forbidden() {
    return (
        <Flex dir="column" justify="center" align="center"
            className="min-vh-100 bgi-size-cover bgi-no-repeat bg-white" // h-100
            style={{ backgroundImage: 'url("/media/bg/bg_prisma.svg")' }} // png
        >
            <Flex wrap dir="row" align="center" className="container">
                <div className="col-md-6 font-weight-bold">
                    <h1 className="font-weight-bolder txt-navy mb-5">{/* fs-25r <div className="h5 font-weight-bolder"></div> */}
                        403 Forbidden
                    </h1>
                    <p>You do not have permission to access this resource.</p>
                    <p>This may be due to one of the following reasons:</p>
                    <ul>
                        <li>You might not have the necessary credentials or authorization to view this page.</li>
                        <li>Your access may be restricted based on your IP address, user role, or other security settings.</li>
                        <li>The resource you're trying to access may be temporarily unavailable or private.</li>
                    </ul>
                    <p>Please ensure you have the correct permissions. If you believe this is an error, kindly contact the website administrator or support team for assistance.</p>
                </div>

                <div className="col-md-6 text-center">
                    <img
                        alt="maintenance"
                        src="/media/bg/403.png"
                        className="d-inline-block max-w-full"
                        loading="eager"
                    />
                </div>
            </Flex>
        </Flex>
    )
}