/* eslint-disable jsx-a11y/anchor-is-valid */
import { Alert } from 'antd'
import React from "react"
import { shallowEqual, useSelector } from "react-redux"
import { Redirect, Switch } from "react-router-dom"; // , useHistory
import "../../../../_metronic/_assets/sass/pages/login/classic/login-1.scss"
import { ContentRoute } from "../../../../_metronic/layout"
import AuthLayout from "../../../layouts/AuthLayout"
import ForgotPassword from "./ForgotPassword"
import Login from "./Login"
import MfaPage from "./MfaPage"
import SsoPage from "./Sso"

export function AuthPage({ redirectUrl }) {
	const { requiresTwoFactor } = useSelector(
		({ auth }) => ({
			requiresTwoFactor: auth.requiresTwoFactor,
		}),
		shallowEqual
	)
	const showAlert = ['demo.unifiplatform.id', 'demo.unifiplatform.asia', 'tenant-management.unifiplatform.id'].includes(window.location.hostname)
	return (
		<AuthLayout>
			<div className="d-flex flex-column flex-root">
				<div
					className="login login-4 login-signin-on d-flex flex-row-fluid"
					id="kt_login"
				>
					<div className="d-flex flex-center flex-column flex-row-fluid bgi-size-cover bgi-position-top bgi-no-repeat">
						{showAlert &&
							(<Alert
								message="WARNING!!!!"
								description={`THIS ENVIRONMENT IS NOT TO BE USED FOR DEMO OR TRAINING PURPOSES.\nIT IS ONLY FOR DEVELOPMENT.  FOR DEMO AND TRAINING, PLEASE USE DEMO.UNIFIPLATFORM.COM OR FINANSYSTEAM.UNIFIPLATFORM.COM`}
								type="warning"
								showIcon
								style={{
									whiteSpace: 'pre-wrap',
									maxWidth: 600,
									marginBottom: 10
								}}
							/>)
						}
						<div className={`${!requiresTwoFactor && 'login-form text-center p-7'} position-relative overflow-hidden`}>
							{requiresTwoFactor ?
								<MfaPage />
								:
								<Switch>
									<ContentRoute path="/authentication/login" component={Login} />
									<ContentRoute
										path="/authentication/forgot-password"
										component={ForgotPassword}
									/>
									<ContentRoute path="/authentication/mfa" component={MfaPage} />
									<ContentRoute path="/authentication/sso" component={SsoPage} />
									<Redirect from="/authentication" exact={true} to="/authentication/login" />
									<Redirect to={redirectUrl} />
								</Switch>
							}
						</div>
					</div>
				</div>
			</div>
		</AuthLayout>
	);
}
