import { useEffect, useState } from "react"
import style from "./style.module.scss"
import {
  MainContainer,
  ChatContainer,
  MessageList,
  Message,
  MessageInput,
  TypingIndicator,
  Loader,
  ConversationHeader,
  Avatar,
} from "@chatscope/chat-ui-kit-react";
import openai from "@utils/openai";
import Markdown from "markdown-to-jsx";
import { CloseButton } from "react-bootstrap";
import { IconButton } from "@material-ui/core";
import { initialMessage, localStorageThreadIdKey } from "../../constants/open-ai";

const OpenAIChat = () => {
    const [ show, setIsShow ] = useState(false)
    const [ loading, setLoading ] = useState({
        thread: false,
        response: false,
        message: false,
    })
    const [ threadId, setThreadId ] = useState()
    const [ messages, setMessages ] = useState([])
    const [ streamData, setStreamData ] = useState(null)

    useEffect(()=> {
        if(!threadId && show){
            retrieveOrCreateThread()
        }
    }, [show])

    useEffect(() => {
        if(threadId){
            retrieveMessages()
        }
    }, [threadId])
    

    const retrieveOrCreateThread = async () => {
        setLoading({...loading, thread: true})
        const threadId = localStorage.getItem(localStorageThreadIdKey)
        if(threadId){
            setThreadId(threadId)
        } else {
            const thread = await openai.beta.threads.create()
            await openai.beta.threads.messages.create(
                thread.id,
                {
                  role: "assistant",
                  content: initialMessage
                }
            )
            setThreadId(thread.id)
            localStorage.setItem(localStorageThreadIdKey, thread.id)
        }
        setLoading({...loading, thread: false})
    }

    const retrieveMessages = async () => {
        setLoading({...loading, message: true})
        const messages = await openai.beta.threads.messages.list(
            threadId
        );
        setMessages(messages.data.reverse())
        setLoading({...loading, message: false})
    }

    const sendMessage = async (text) => {
        setLoading({...loading, message: true})
        try{
            await openai.beta.threads.messages.create(
                threadId,
                {
                  role: "user",
                  content: text
                }
            )
            await retrieveMessages()
            setLoading({...loading, message: false, response: true})
            let token = ""
            openai.beta.threads.runs.stream(threadId, {
                assistant_id: process.env.REACT_APP_OPEN_AI_ASSISTANT_ID
              })
                .on('messageDelta', (delta) => {
                    const content = delta.content?.[0]?.text?.value
                    token = (`${token}${content}`).replace(/【.*?】/g, '')
                    const assistantMessage = {
                        content: token,
                        role: 'assistant',
                    }
                    setStreamData(assistantMessage)
                })
                .on('end', async () => {
                    await retrieveMessages()
                    setStreamData(null)
                    setLoading({...loading, response: false})
                })
        } catch (e){
            console.error(e);
        }
    }

    return (
        show ? 
            <div className={style["chat-wrapper"]}>
                <MainContainer className={style["chat-main-container"]}>
                    {
                        loading.message && !messages.length? <Loader style={{margin: "auto"}} /> :
                            <ChatContainer>
                                <ConversationHeader>
                                    <Avatar
                                        name="Uni"
                                        src="/icons/ms-icon-310x310.png"
                                    />
                                    <ConversationHeader.Content
                                        info="UniFi Assistant"
                                        userName="Uni"
                                    />
                                    <ConversationHeader.Actions>
                                        <CloseButton onClick={()=> setIsShow(false)} />
                                    </ConversationHeader.Actions>
                                </ConversationHeader>
                                <MessageList typingIndicator={loading.response? <TypingIndicator />: null}>
                                {
                                    (messages || []).map((message) => {
                                        return (message.content || []).map((content, index)=> {
                                            const { text } = content
                                            return (
                                                <Message
                                                    key={`${message.id}-${index}`}
                                                    model={{
                                                        message: text?.value.replace(/【.*?】/g, ''),
                                                        direction: message.role === "user"? "outgoing": "incoming",
                                                        position: "single"
                                                    }}
                                                >
                                                    <Message.CustomContent>
                                                        <Markdown>{text?.value.replace(/【.*?】/g, '')}</Markdown>
                                                    </Message.CustomContent>
                                                </Message>
                                            )
                                        })
                                    })
                                }
                                {
                                    streamData
                                        ? (
                                            <Message
                                                key={`${streamData?.msgId}`}
                                                model={{
                                                    message: streamData?.content,
                                                    direction: "incoming",
                                                    position: "single"
                                                }}
                                            >
                                                <Message.CustomContent>
                                                    <Markdown>{streamData?.content}</Markdown>
                                                </Message.CustomContent>
                                            </Message>
                                        )
                                        : null
                                }
                                </MessageList>
                                <MessageInput className="bg-white" attachButton={false} onSend={(text)=> sendMessage(text)} placeholder="Type message here" />
                            </ChatContainer>
                    }
                </MainContainer>
            </div>
            :
            <IconButton
                className={`bg-primary text-white ${style['chat-button']}`}
                onClick={()=> setIsShow(true)}
            >
                <i className="fa fa-regular fa-comments icon-xl"></i>
            </IconButton>
    )
}

export default OpenAIChat